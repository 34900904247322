import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authReducer';

const store = configureStore({
    reducer: {
        auth: authReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

// Initialisation de l'état à partir du stockage local
const storedUserString = localStorage.getItem('user');
const storedUser = storedUserString ? JSON.parse(storedUserString) : null;

if (storedUser && storedUser.expiration && storedUser.expiration > Date.now()) {
    store.dispatch({ type: 'LOGIN', payload: storedUser.user });
} else {
    localStorage.removeItem('user');
}


export default store;