import {getBookByID, getUserBookByID, updateUserBook} from "../../../api/book";
import lang from "../../../lang.json";
import {langue} from "../../../function/function";
import {CreateToastFnReturn} from "@chakra-ui/react";
import {getAllListsByUser} from "../../../api/list";
import {initEmptyImage} from "../image/fetch";


export interface ListOptions {
    id: string,
    label: string,
    value: string
}

export const fetchBook = (id: string, user: User, setBook: (value: UserBook) => void) => {
    getUserBookByID(id, user.id).then((res) => {
        if (res.data.status === 200) {
            setBook(res.data.book as UserBook)
        } else {
            getBookByID(id).then((res => {
                if (res.data.status === 200) {
                    const resultBook = res.data.book as Book

                    setBook({
                        id: resultBook.id,
                        title: resultBook.title,
                        description: resultBook.description,
                        releaseDate: resultBook.releaseDate,
                        author: resultBook.author,
                        editor: resultBook.editor,
                        genres: resultBook.genres,
                        image: resultBook.image,
                        started: null,
                        finished: null
                    })
                }
            }))
        }
    })
}

export const fetchBookList = (user: User, bookID: string, setListOptions: (value: ListOptions[]) => void, setSelectedList: (value: ListOptions[]) => void, setPreviousList: (value: ListOptions[]) => void) => {
    getAllListsByUser(user.id).then((res) => {
        if (res.data.status === 200) {
            const lists = res.data.lists as List[]
            const options: ListOptions[] = []
            const selectedList: ListOptions[] = []

            lists.forEach(list => {
                if (list.books.map(book => book.id).includes(bookID)) {
                    push(selectedList, list)
                }

                push(options, list)
            })

            setListOptions(options)
            setPreviousList(selectedList)
            setSelectedList(selectedList)
        }
    })
}

function push(list: ListOptions[], value: List) {
    list.push({
        id: value.id,
        label: value.name,
        value: value.name.toLowerCase()
    })
}

export const handleSave = (toast: CreateToastFnReturn, user: User, book: UserBook, modifiedKey: string) => {
    updateUserBook(user.id, book, modifiedKey).then(res => {
        if (res.data.status === 500) {
            toast({
                description: lang[langue].errorToast,
                status: "error",
                duration: 2000,
                isClosable: true,
                position: 'top'
            })
        } else {
            let message: string | null = null

            switch (res.data.status) {
                case 201:
                    message = lang[langue].startDateMessage + " " + lang[langue].modified
                    break;
                case 202:
                    message = lang[langue].endDateMessage + " " + lang[langue].modified
                    break;
                case 203:
                    message = lang[langue].startDateMessage + " " + lang[langue].reset
                    break;
                case 204:
                    message = lang[langue].endDateMessage + " " + lang[langue].reset
                    break;
            }

            if (!message) {
                toast({
                    description: "Error",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                    position: 'top'
                })
            } else {
                toast({
                    description: message,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: 'top'
                })
            }
        }
    })
}

export const initEmptyBook = (): Book => {
    return {
        id: "",
        title: "",
        description: "",
        releaseDate: null,
        author: null,
        editor: null,
        image: initEmptyImage(),
        genres: [],
    }
}

export const initEmptyUserBook = (): UserBook => {
    return {
        id: "",
        title: "",
        description: "",
        releaseDate: null,
        author: null,
        editor: null,
        image: initEmptyImage(),
        genres: [],
        started: null,
        finished: null
    }
}