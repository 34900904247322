import React, {FC, useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    HStack,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ScaleFade,
    Tag,
    Text,
    useDisclosure,
    useToast,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {deleteListByID, getAllListsByUser} from "../../../api/list";
import {useNavigate} from "react-router-dom";
import {FiEdit} from "react-icons/fi";
import {RiDeleteBinLine} from "react-icons/ri";
import lang from "../../../lang.json";
import {baseURL, langue} from "../../../function/function";
import HeaderGeneral from "../../../component/header/HeaderGeneral";
import {FaPlus} from "react-icons/fa";

const Home: FC = () => {
    const toast = useToast();
    const navigate = useNavigate();

    const {onOpen, isOpen, onClose} = useDisclosure()
    const [deletedItems, setDeletedItems] = useState<string[]>([])
    const [lists, setList] = useState<List[]>([])
    const user = useSelector((state: RootState) => state.auth.user) as User;
    const [deleteID, setDeleteID] = useState<string>("")

    useEffect(() => {
        fetchLists()
    }, [])

    const fetchLists = (): void => {
        getAllListsByUser(user.id).then((res) => {
            if (res.data.status === 200) {
                setList(res.data.lists as List[])
            }
        })
    }

    const handleItemClick = (id: string) => navigate(`/lists/content/${id}`);
    const handleEditClick = (id: string) => navigate(`/lists/update-list/?id=${id}&step=1`);

    const handleFade = (itemID: string) => {
        setDeletedItems((prevItems) => [...prevItems, itemID])
        onClose()

        handleDeleteClick(itemID)
    }

    const handleDeleteClick = (id: string) => {
        deleteListByID(id)
            .then((res) => {
                if (res.data.status === 200) {
                    toast({
                        description: lang[langue].successDeleted,
                        status: "success",
                        duration: 2000,
                        isClosable: true
                    })

                    setTimeout(() => {
                        setList(prevLists => prevLists.filter(list => list.id !== id));
                    }, 1000)
                }
            })
            .catch(() => {
                toast({
                    description: lang[langue].errorToast,
                    status: "error",
                    duration: 2000,
                    isClosable: true
                })
            })
    }

    const handleOpenModal = (e: React.MouseEvent<SVGElement, MouseEvent>, id: string) => {
        e.stopPropagation();
        setDeleteID(id)
        onOpen();
    }

    const handleCloseModal = () => {
        setDeleteID("")
        onClose()
    }

    const handleCreateClick = (): void => {
        navigate("/lists/create-list");
    }

    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"} p={"0"}>
            <HeaderGeneral/>
            <Flex mt={"10px"} ml={8}>
                <IconButton aria-label={"add"} colorScheme={"tb"} width={"50px"}
                            boxShadow="3px 3px 4px rgba(0, 0, 0, 0.3)"
                            onClick={() => navigate("/lists/create-list/?step=1")} icon={<FaPlus color={"black"}/>}/>
            </Flex>

            <Grid p={"20px"} width={"100%"} rowGap={6}
                  templateColumns={{
                      base: "100%",
                      lg: "repeat(2, calc(100% / 2))",
                      xl: "repeat(3, calc(100% / 3))",
                      "2xl": "repeat(4, calc(100% / 4))"
                  }}
            >
                {lists.map((list: List) => (
                    <GridItem mr={3} ml={3}>
                        <ScaleFade initialScale={1} transition={{exit: {delay: 0.2}, enter: {duration: 0.5}}}
                                   key={list.id}
                                   in={!deletedItems.includes(list.id)}
                                   style={{height: "100%"}}>
                            <Card bg={'#A78295'} boxShadow={"dark-lg"} onClick={() => handleItemClick(list.id)}
                                  transition={"transform 0.2s"}
                                  _hover={{
                                      cursor: "pointer",
                                      transform: "scale(1.05)",
                                  }}
                                  h={"100%"}
                            >
                                <CardHeader p={4} minH={'110px'}>
                                    <Flex justifyContent={"space-between"} gap={"5px"}>
                                        <Heading size={"mg"} overflow={"hidden"}
                                                 whiteSpace={"nowrap"}>{list.name}</Heading>

                                        <HStack spacing={"15px"}>
                                            <FiEdit size={"27px"} cursor={"pointer"} className={"icon2"}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditClick(list.id);
                                                    }}/>

                                            <RiDeleteBinLine size={"27px"} cursor={"pointer"} className={"icon1"}
                                                             onClick={(e) => handleOpenModal(e, list.id)}/>
                                        </HStack>
                                    </Flex>

                                    <Text>{list.books.length} livres</Text>

                                    <Wrap>
                                        {list.genres.map((genre: Genre) => (
                                            <WrapItem>
                                                <Tag background={"#3F2E3E"} color={"white"}
                                                     fontWeight={"unset"}>{genre.name}</Tag>
                                            </WrapItem>
                                        ))}
                                    </Wrap>
                                </CardHeader>

                                <CardBody pt={0} p={4}>
                                    <Flex alignItems={"center"} justifyContent={"center"}>
                                        {
                                            list?.image ?
                                                <Image src={baseURL + "/" + list.image.path}
                                                       alt={list.image.name}
                                                       borderRadius='lg'
                                                       maxW={"100%"}
                                                       h={"auto"}
                                                       aspectRatio={"16/9"}
                                                /> :
                                                <Center>
                                                    <Text>{lang[langue].noImage}</Text>
                                                </Center>
                                        }
                                    </Flex>
                                </CardBody>
                            </Card>
                        </ScaleFade>
                    </GridItem>
                ))}
            </Grid>

            <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered
                   closeOnOverlayClick={false} motionPreset='slideInBottom'>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>{lang[langue].suppressListModalTitle}</ModalHeader>
                    <ModalCloseButton/>

                    <ModalBody>
                        <Text>{lang[langue].suppressListModalDetail}</Text>
                    </ModalBody>

                    <ModalFooter gap={"15px"}>
                        <Button colorScheme={"green"}
                                onClick={() => handleFade(deleteID)}>{lang[langue].validate}</Button>
                        <Button colorScheme='red' mr={3} onClick={handleCloseModal}>
                            {lang[langue].cancel}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    )
};

export default Home;