import React, {FC, useEffect, useState} from "react";
import {
    Box, Button,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftElement,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle, useToast
} from "@chakra-ui/react";
import {baseURL, langue} from "../../../function/function";
import lang from "../../../lang.json";
import {IoSearch} from "react-icons/io5";
import BackHeader from "../../../component/header/BackHeader";
import {getBooks, handleSaveLastPart, initEmptyList, listSteps} from "../../fetchActions/list/updateList";
import {getListByUserAndID} from "../../../api/list";
import {CreateListProps} from "../../../component/componentInterface";
import {useLocation, useNavigate} from "react-router-dom";
import {AddButton, CheckButton} from "../../../component/button/Button";

const S3CreateList: FC<CreateListProps> = ({headerTitle, previousLink, user}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    const navigate = useNavigate();
    const toast = useToast();

    const [books, setBooks] = useState<Book[]>([]);
    const [search, setSearch] = useState<string>("");

    //List
    const [previousList, setPreviousList] = useState<List>(initEmptyList(user))
    const [list, setList] = useState<List>(initEmptyList(user))

    useEffect(() => {
        let list = initEmptyList(user)

        if (id) {
            getListByUserAndID(user.id, id).then((res) => {
                if (res.data.status === 200) {
                    list = res.data.list as List
                } else {
                    navigate("/lists/create-list/?step=1")
                }

                setPreviousList(list)
                setList(list)
            })
        } else {
            setList(list)
        }
    }, []);

    useEffect(() => {
        getBooks(search, setBooks)
    }, [search]);

    const handleSearch = (search: string) => {
        setSearch(search)
    }

    const handleAdd = (book: Book) => {
        setList({...list, books: [...list.books, book]});
    };

    const handleRemove = (book: Book) => {
        let updatedList = [...list.books];
        let index = updatedList.map(book => book.id).indexOf(book.id);

        if (index !== -1) {
            updatedList.splice(index, 1);
            setList({...list, books: updatedList})
        }
    }

    const handleSave = () => {
        handleSaveLastPart(previousList, list).then((res) => {
            if (res.status === 400 || res.status === 200) {
                if (res.status === 200) {
                    toast({
                        description: lang[langue].successUpdated,
                        status: "success",
                        duration: 2500,
                        isClosable: true,
                    });
                }

                navigate("/lists/")
            } else {
                toast({
                    description: lang[langue].errorToast,
                    status: "error",
                    duration: 2500,
                    isClosable: true,
                });
            }
        });
    }

    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"} p={"0"}>
            <BackHeader headerTitle={headerTitle} previousLink={"/lists"}/>

            <Center>
                <Flex alignItems={"center"} height={"55px"} transition={"width 0.4s"}
                      width={{base: "100%", sm: "95%", md: "80%", lg: "70%", xl: "50%"}}>
                    <Stepper index={2} colorScheme={"tb"} w={"100%"} p={2}>
                        {listSteps.map((step, index) => (
                            <Step key={index} className={"stepper"}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon/>}
                                        incomplete={<StepNumber/>}
                                        active={<StepNumber/>}
                                    />
                                </StepIndicator>

                                <Box flexShrink='0'>
                                    <StepTitle>{step.title}</StepTitle>
                                    <StepDescription>{step.description}</StepDescription>
                                </Box>

                                <StepSeparator/>
                            </Step>
                        ))}
                    </Stepper>
                </Flex>
            </Center>

            <Flex flexDirection={"column"} w={"100%"} p={2} minH={"calc(100vh - 100px - 55px - 50px)"}>
                <InputGroup maxW={"400px"}>
                    <InputLeftElement>
                        <IoSearch/>
                    </InputLeftElement>
                    <Input type='text' variant={"custom"} placeholder={lang[langue].researchBook}
                           onChange={(event) => handleSearch(event.target.value)}/>
                </InputGroup>

                <Grid mt={4} gridGap={"10px"} justifyItems={"center"} templateColumns={{
                    base: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(4, 1fr)',
                    lg: 'repeat(5, 1fr)',
                    xl: 'repeat(6, 1fr)'
                }}>
                    {books.map((book) => (
                        <Center maxW={"300px"}>
                            <GridItem key={book.id}>
                                <div className="card">
                                    <div className="image-box">
                                        {<img src={baseURL + "/" + book.image?.path} alt={"Image"}/>}
                                    </div>
                                    <div className="text-box">
                                        <div className="main">
                                            <span>{book.title}</span>
                                        </div>

                                        <div className={"bar"}></div>

                                        <div className="sub">
                                            <span>{book.author ? book.author.firstName + " " + book.author.lastName.toUpperCase() : ""}</span>
                                        </div>
                                    </div>

                                    {list.books.map(book => book.id).includes(book.id) ?
                                        <CheckButton colorScheme={"teal"} onClick={() => handleRemove(book)}/> :
                                        <AddButton colorScheme={"gray"} onClick={() => handleAdd(book)}/>
                                    }
                                </div>
                            </GridItem>
                        </Center>
                    ))}
                </Grid>
            </Flex>

            <Flex justifyContent={"space-between"} h={"50px"} pr={2} pl={2}>
                <Button colorScheme={"tb"} onClick={() => navigate(previousLink + "?id=" + list.id + "&step=2")}>
                    {lang[langue].previous}
                </Button>

                <Button colorScheme={"green"} onClick={handleSave}>
                    {lang[langue].validate}
                </Button>
            </Flex>
        </Container>
    )
};

export default S3CreateList;