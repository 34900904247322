import {TextInputProps} from "../componentInterface";
import {Input} from "@chakra-ui/react";
import React from "react";

function TextInput({label, placeholder, type, onChange, onBlur, value, isRequired, isInvalid}: TextInputProps) {
    return (
        <Input
            type={type}
            className={"input"}

            w={"100%"}
            border={"solid 1.5px black"}
            errorBorderColor={"darkred"}
            placeholder={placeholder ? placeholder : label}

            _hover={{borderColor: "tb.900"}}
            _placeholder={{color: "rgba(63, 63, 63, 0.8)"}}

            onChange={(event) => onChange ? onChange(event.target.value) : null}
            onBlur={(event) => onBlur ? onBlur(event.target.value) : null}
            value={value}

            isInvalid={isInvalid ? isInvalid : false}
            isRequired={isRequired ? isRequired : false}
        />
    )
}

export default TextInput;