import axios, {AxiosResponse} from "axios";
import {baseURL} from "../function/function";

export const addImageToList = async (image: Image, list: List) => {
    return await axios.post(
        baseURL + `/image/${image.id}/list/${list.id}`,
        {headers: {'Content-Type': 'application/json'}}
    ) as AxiosResponse<GenreApiDataType>;

}

export const removeImageFromList = async (image: Image, list: List) => {
    return await axios.delete(
        baseURL + `/image/${image.id}/list/${list.id}`,
        {headers: {'Content-Type': 'application/json'}}
    ) as AxiosResponse<GenreApiDataType>;
}

export const deleteGlobalImage = async (image: Image) => {
    return await axios.delete(
        baseURL + `/image/${image.id}`,
        {headers: {'Content-Type': 'application/json'}}
    ) as AxiosResponse<GenreApiDataType>;
}