import {addBookToList, getListByUserAndID, removeBookToList, updateListByID, updateListGenre} from "../../../api/list";
import {getAllGenre} from "../../../api/genre";
import {GenreOptions} from "../../interface";
import {langue} from "../../../function/function";
import lang from "../../../lang.json"
import {initEmptyImage} from "../image/fetch";
import {getAllBooks, getAllBooksFilterByName} from "../../../api/book";

export function initEmptyList(user: User): List {
    return {
        id: "",
        name: "",
        user: user,
        genres: [],
        books: [],
        creationDate: new Date(),
        image: initEmptyImage()
    }
}

export const listSteps = [
    {title: "First", description: lang[langue].infoList},
    {title: 'Second', description: lang[langue].image},
    {title: 'Third', description: lang[langue].addBook},
]

export function fetchList(id: string | null, user: User, setList: (list: List) => void) {
    let list = initEmptyList(user)

    if (id) {
        getListByUserAndID(user.id, id).then((res) => {
            if (res.data.status === 200) {
                list = res.data.list as List
            }

            setList(list)
        })
    } else {
        setList(list)
    }
}

export function fetchListWithPrevious(id: string | null, user: User, setList: (list: List) => void, setPreviousList: (list: List) => void) {
    let list = initEmptyList(user)

    if (id) {
        getListByUserAndID(user.id, id).then((res) => {
            if (res.data.status === 200) {
                list = res.data.list as List
            }

            setList(list)
            setPreviousList(list)
        })
    } else {
        setList(list)
    }
}

export function fetchAllGenres(setAllGenres: (genres: Genre[]) => void) {
    getAllGenre().then((res) => {
        setAllGenres(res.data.genres as Genre[])
    })
}

export function fetchGenres(list: List, genres: Genre[], setGenreOptions: (genreOptions: GenreOptions[]) => void, setSelectedGenres: (selectedGenres: GenreOptions[]) => void) {
    const genreOptions: GenreOptions[] = [];
    const selectedGenre: GenreOptions[] = []

    genres.forEach(genre => {
        genreOptions.push({
            genre: genre,
            value: genre.name.toLowerCase(),
            label: genre.name
        })
    })

    list.genres.forEach(genre => {
        const option = genreOptions.find((options) => options.genre.id === genre.id)

        if (option) {
            selectedGenre.push(option)
        }
    })

    setGenreOptions(genreOptions)
    setSelectedGenres(selectedGenre)
}

export function getBooks(search: string, setBooks: (books: Book[]) => void) {
    if (search) {
        getAllBooksFilterByName(search).then((res) => {
            setBooks(res.data.books as Book[]);
        });
    } else {
        getAllBooks().then((res) => {
            setBooks(res.data.books as Book[]);
        });
    }
}


export async function handleSaveFirstPart(previousList: List, list: List) {
    let result: ListApiDataType = {
        status: 400,
        message: "No change",
        list: list
    }

    if (list !== previousList) {
        result = {
            status: 404,
            message: "Failed"
        }

        const saveResult = await handleSave(list)
        if (saveResult.data.status == 200) {
            let saveList = saveResult.data.list as List
            list.id = saveList.id

            const saveGenreResult = await handleGenreSave(list)

            if (saveGenreResult.data.status === 200) {
                result = saveGenreResult.data
            }
        }
    }

    return result
}

export async function handleSaveLastPart(previousList: List, list: List) {
    const removeBooks: Book[] = []
    const addBooks: Book[] = []

    let result: ListApiDataType = {
        status: 400,
        message: "No change",
    }

    if (list !== previousList) {
        let error = false;
        result = {
            status: 404,
            message: "Failed"
        }

        previousList.books.forEach((book) => {
            if (!list.books.map(book => book.id).includes(book.id)) {
                removeBooks.push(book)
            }
        });

        list.books.forEach((book) => {
            if (!previousList.books.map(book => book.id).includes(book.id)) {
                addBooks.push(book)
            }
        });

        removeBooks.forEach((book) => {
            removeBookToList(list.id, book.id).then((row) => {
                error = row.data.status !== 200;
            })
        })

        addBooks.forEach((book) => {
            addBookToList(list.id, book.id).then((row) => {
                error = row.data.status !== 200;
            })
        })

        if (!error) {
            result = {
                status: 200,
                message: "Success",
            }
        }
    }

    return result
}


export function handleSave(list: List) {
    return updateListByID(list)
}

export function handleGenreSave(list: List) {
    return updateListGenre(list);
}