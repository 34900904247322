import {ReactNode} from "react";

export interface InputProps {
    fieldName: string;
    label: string;
    placeholder?: string;
    required: boolean;
    errorFunction?: ValidationFunction;
    onChange?: (value: string) => void;
    disabled?: boolean;
}

export interface InputPropsWithType extends InputProps {
    type: InputTypeEnum;
}

export interface SelectInputProps {
    name: string;
    placeholder?: string
    onChange?: (value: string) => void;
    children: ReactNode
}

export interface TextInputProps {
    label: string;
    value: string;
    type: InputTypeEnum;
    placeholder?: string;

    isInvalid?: boolean;
    isRequired?: boolean;

    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
}

export enum InputTypeEnum {
    TEXT = "text",
    EMAIL = "email",
    PASSWORD = "password",
    MONTH = "month",
    DATE = "date",
    DATETIME_LOCAL = "datetime-local",
    PHONE = "tel",
    COLOR = "color",
}


export interface HeaderTitleProps {
    headerTitle: string;
}

export interface HeaderGeneralProps extends HeaderTitleProps {
    previousLink: string;
}

export interface CreateListProps extends HeaderGeneralProps {
    user: User;
}

export type ValidationFunction = (value: string, key: string) => string | undefined;