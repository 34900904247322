import React from 'react';
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import store from "./redux/store";
import {Provider} from "react-redux";
import ConnexionPage from "./views/pages/connexion/ConnexionPage";
import CreationAccountPage from "./views/pages/connexion/CreationAccountPage";
import Home from "./views/pages/lists/Home";
import BookDetails from "./views/pages/books/BookDetails";
import {inputTheme} from "./theme";
import CreateListPage from "./views/pages/lists/CreateListPage";
import CreateBook from "./views/pages/books/CreateBook";
import {langue} from "./function/function";
import lang from "./lang.json";
import ListContent from "./views/pages/lists/ListContent";
import AddBooksToList from "./views/pages/books/AddBooksToList";

const baseStyle = {
    separator: {
        bg: "gray",
    },

    indicator: {
        borderColor: "gray",
    }
}

const stepperTheme = {
    baseStyle,
}

const theme = extendTheme({
    base: "0px",
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",

    colors: {
        tb: {
            100: "#EFE1D1",
            200: "#c5b5a3",
            500: "#A78295",
            600: "#826273",
            700: "#4E2C43",
            800: "#331D2C",
            900: "#271622",
        },
    },

    components: {
        Stepper: stepperTheme,
        Input: inputTheme
    },
});

function App() {
    return (
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<ConnexionPage/>}/>
                        <Route path="/createAccount" element={<CreationAccountPage/>}/>
                        <Route path="/passwordForgotten" element={<CreationAccountPage/>}/>
                        <Route path="/lists" element={<Home/>}/>
                        <Route path="/lists/create-list/"
                               element={<CreateListPage headerTitle={lang[langue].createListTitle}
                                                        previousLink={"/lists/create-list/"}/>}/>
                        <Route path="/lists/update-list/"
                               element={<CreateListPage headerTitle={lang[langue].updateListTitle}
                                                        previousLink={"/lists/update-list/"}/>}/>
                        <Route path="/books/create-book" element={<CreateBook/>}/>
                        <Route path="/lists/books/:id" element={<BookDetails/>}/>
                        <Route path="/lists/content/:id" element={<ListContent/>}/>
                        <Route path="/lists/content/:id/add-books" element={<AddBooksToList/>}/>
                    </Routes>
                </BrowserRouter>
            </ChakraProvider>
        </Provider>
    );
}

export default App;
