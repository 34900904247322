import React, {FC, useEffect, useState} from 'react';
import {InboxOutlined} from '@ant-design/icons';
import {Upload, UploadProps} from 'antd';
import {
    Box,
    Button,
    Card,
    CardBody,
    Center,
    Container,
    Flex,
    Heading,
    Image,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle,
    useToast
} from "@chakra-ui/react";
import {baseURL, langue} from "../../../function/function";
import lang from "../../../lang.json";
import {BsTrash3} from "react-icons/bs";
import {handleAdd, handleRemove, initEmptyImage} from "../../fetchActions/image/fetch";
import {useLocation, useNavigate} from "react-router-dom";
import {initEmptyList, listSteps} from "../../fetchActions/list/updateList";
import {CreateListProps} from "../../../component/componentInterface";
import BackHeader from "../../../component/header/BackHeader";
import {getListByUserAndID} from "../../../api/list";

const S2CreateList: FC<CreateListProps> = ({headerTitle, previousLink, user}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    const toast = useToast()
    const navigate = useNavigate();
    const {Dragger} = Upload;

    //List
    const [list, setList] = useState<List>(initEmptyList(user))

    useEffect(() => {
        let list = initEmptyList(user)

        if (id) {
            getListByUserAndID(user.id, id).then((res) => {
                if (res.data.status === 200) {
                    list = res.data.list as List
                } else {
                    navigate("/lists/create-list/?step=1")
                }

                setList(list)
            })
        } else {
            setList(list)
        }
    }, []);


    const props: UploadProps = {
        name: 'file',
        action: baseURL + '/uploadImage/',
        accept: '.jpg,.png,.jpeg',
        maxCount: 1,
        showUploadList: false,

        onChange(info) {
            const {status} = info.file;

            if (status === "uploading") {
                if (list && list.image && list.image.id != "") {
                    handleRemove(list.image, list)
                    setList(
                        {
                            ...list,
                            image: initEmptyImage()
                        }
                    )
                }
            }

            if (status === 'done') {
                handleAdd(info.file.response.image, list, toast)

                setList({
                    ...list,
                    image: info.file.response.image
                });
            } else if (status === 'error') {
                toast({
                    description: lang[langue].errorToast,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            }
        },

        onDrop() {
        }
    };

    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"} p={"0"}>
            <BackHeader headerTitle={headerTitle} previousLink={"/lists"}/>

            <Center>
                <Flex alignItems={"center"} height={"55px"} transition={"width 0.4s"}
                      width={{base: "100%", sm: "95%", md: "80%", lg: "70%", xl: "50%"}}>
                    <Stepper index={1} colorScheme={"tb"} w={"100%"} p={2}>
                        {listSteps.map((step, index) => (
                            <Step key={index} className={"stepper"}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon/>}
                                        incomplete={<StepNumber/>}
                                        active={<StepNumber/>}
                                    />
                                </StepIndicator>

                                <Box flexShrink='0'>
                                    <StepTitle>{step.title}</StepTitle>
                                    <StepDescription>{step.description}</StepDescription>
                                </Box>

                                <StepSeparator/>
                            </Step>
                        ))}
                    </Stepper>
                </Flex>
            </Center>

            <Flex flexDirection={{base: "column", xl: "row"}} alignItems={"center"} justifyContent={"center"}
                  minH={"calc(100vh - 100px - 55px - 50px)"} p={"10px"} gap={'30px'}>
                {list.image && list.image.id !== "" ?
                    <Card key={list.id} maxW={"lg"} background={"tb.500"}>
                        <CardBody>
                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                <Heading size={"lg"}>{lang[langue].image}</Heading>

                                <BsTrash3 size={"20px"} cursor={"pointer"} className={"icon1"}
                                          onClick={() => {
                                              handleRemove(list.image, list, toast)
                                              setList(
                                                  {
                                                      ...list,
                                                      image: initEmptyImage()
                                                  }
                                              )
                                          }}
                                />
                            </Flex>

                            <Image src={baseURL + "/" + list.image.path} alt={list.image.name} borderRadius='lg'
                                   mt={"15px"}
                                   loading="lazy" maxH={"350px"}/>
                        </CardBody>
                    </Card>
                    : null
                }

                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{color: "#A78295"}}/>
                    </p>

                    <p className="ant-upload-text">{lang[langue].uploadTitle}</p>
                    <p className="ant-upload-hint">
                        {lang[langue].uploadDesc}
                        {lang[langue].extensions}
                    </p>
                </Dragger>
            </Flex>

            <Flex justifyContent={"space-between"} h={"50px"} pr={2} pl={2}>
                <Button colorScheme={"tb"} onClick={() => navigate(previousLink + "?id=" + list.id + "&step=1")}>
                    {lang[langue].previous}
                </Button>

                <Button colorScheme={"tb"} onClick={() => navigate(previousLink + "?id=" + list.id + "&step=3")}>
                    {lang[langue].next}
                </Button>
            </Flex>
        </Container>
    )
};

export default S2CreateList;