import {addImageToList, deleteGlobalImage, removeImageFromList} from "../../../api/image";
import {CreateToastFnReturn} from "@chakra-ui/react";
import {langue} from "../../../function/function";
import lang from "../../../lang.json";

export function initEmptyImage(): Image {
    return {
        id: "",
        name: "",
        path: ""
    }
}

export function handleAdd(image: Image, list: List, toast: CreateToastFnReturn) {
    addImageToList(image, list).then((res) => {
        if (res.data.status === 200) {
            toast({
                description: lang[langue].successUploaded,
                status: "success",
                duration: 2000,
                isClosable: true,
            })
        }
    })
}

export function handleRemove(image: Image, list: List, toast?: CreateToastFnReturn) {
    removeImageFromList(image, list).then((res) => {
        if (res.data.status === 200) {
            handleDeleteImage(image, toast)
        }
    })
}

function handleDeleteImage(image: Image, toast?: CreateToastFnReturn) {
    deleteGlobalImage(image).then((res) => {
        if (res.data.status === 200 && toast) {
            toast({
                description: lang[langue].successDeleted,
                status: "success",
                duration: 2000,
                isClosable: true,
            })
        }
    })
}