import React, {FC, useEffect, useState} from "react";
import {
    Card,
    CardBody,
    CloseButton,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Link as ChakraLink,
    Stack,
    Text
} from "@chakra-ui/react";
import {FaPlus} from "react-icons/fa";
import BackHeader from "../../../component/header/BackHeader";
import {getListByID} from "../../../api/list";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {Link as ReactRouterLink, useNavigate, useParams} from "react-router-dom";
import LimitedText from "../../../component/input/LimitedText";
import {IoSearch, IoShuffle} from "react-icons/io5";
import {baseURL, langue} from "../../../function/function";
import SelectInput from "../../../component/input/SelectInput";
import lang from "../../../lang.json";
import {initEmptyList} from "../../fetchActions/list/updateList";

const ListContent: FC = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const user = useSelector((state: RootState) => state.auth.user) as User;
    const [list, setList] = useState<List>(initEmptyList(user));
    const [crossHidden, setCrossHidden] = useState<boolean>(true);
    const [books, setBooks] = useState<Book[]>([]);
    const [search, setSearch] = useState<string>("");


    useEffect(() => {
        if (id) {
            getListByID(id).then((res) => {
                if (res.data.status === 200) {
                    const list = res.data.list as List
                    setList(list)
                    setBooks(list.books)
                    console.log("init")
                }
            })
        }
    }, [id]);

    useEffect(() => {
        setBooks(list.books.filter(book => book.title.toLowerCase().includes(search.toLowerCase())))
    }, [search]);

    function setOrderBooks(value: string) {
        const booksFiltered = list.books.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
        setBooks([...booksFiltered])
    }

    function shuffleArray(array: Book[]) {
        const number = Math.floor(Math.random() * array.length);
        return array[number];
    }

    function handleShuffleClick() {
        const shuffledBooks = shuffleArray(list.books);
        setBooks([shuffledBooks])
        setCrossHidden(false)
    }

    function handleShuffleCross() {
        setBooks(list.books)
        setCrossHidden(true)
    }

    const handleSearch = (search: string) => {
        setSearch(search)
    }

    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"} p={"0"}>
            <BackHeader headerTitle={lang[langue].detailsList} previousLink={"/lists"}/>

            <Flex justify={"space-between"} mt={"10px"} ml={"30px"} mr={"30px"}>
                <Flex width={"250px"} gap={"35px"}>
                    <IconButton aria-label={"add"} colorScheme={"tb"} width={"50px"} onClick={() => navigate(`/lists/content/${id}/add-books`)}
                                boxShadow="3px 3px 4px rgba(0, 0, 0, 0.3)">
                        <FaPlus color={"black"}/>
                    </IconButton>

                    <Flex gap={"5px"} alignItems={"center"}>
                        <IconButton aria-label={"shuffle"} colorScheme={"tb"} width={"50px"}
                                    boxShadow="3px 3px 4px rgba(0, 0, 0, 0.3)" onClick={handleShuffleClick}>
                            <IoShuffle color={"black"} size={"25px"}/>
                        </IconButton>

                        <CloseButton size={"md"} hidden={crossHidden} onClick={handleShuffleCross}/>
                    </Flex>
                </Flex>

                <InputGroup maxW={"400px"}>
                    <InputLeftElement>
                        <IoSearch/>
                    </InputLeftElement>
                    <Input type='text' variant={"custom"} placeholder={lang[langue].researchBook}
                           onChange={(event) => handleSearch(event.target.value)}/>
                </InputGroup>

                <Flex width={"250px"}>
                    <SelectInput name={"Tris"} placeholder={lang[langue].oldSort}
                                 onChange={(value) => setOrderBooks(value)}>
                        <option value='addRecently'>{lang[langue].newSort}</option>
                        <option value='asc'>{lang[langue].alphabeticalSort}</option>
                    </SelectInput>
                </Flex>

            </Flex>


            <Grid templateColumns='repeat(4, 1fr)' gap={6} pt={"30px"} pl={"10px"} pr={"20px"}>
                {books.map((book: Book) => (
                    <ChakraLink as={ReactRouterLink} to={`/lists/books/${book.id}`} textDecoration="none"
                                _hover={{textDecoration: 'none'}}>
                        <GridItem w='100%' borderRadius={"10"} transform={"scale(0.9)"}
                                  boxShadow={"dark-lg"} _hover={{transform: "scale(0.95)"}}>
                            <Card direction={{base: 'column', sm: 'row'}}
                                  overflow='hidden'
                                  bg={'#A78295'}>
                                <Image src={baseURL + "/" + book.image.path} maxH={"200px"} borderRadius={"5px"}/>
                                <Stack spacing='3'>
                                    <CardBody>
                                        <Heading size={"s"}>{book.title}</Heading>
                                        <Text>{book.author ? book.author.firstName + " " + book.author.lastName : ""}</Text>
                                        <Text fontSize={"12"}>{book.editor ? book.editor.name : ""}</Text>
                                        <LimitedText fontSize={"11"} maxWords={25}>
                                            {book.description ? book.description : ""}
                                        </LimitedText>
                                    </CardBody>
                                </Stack>
                            </Card>
                        </GridItem>
                    </ChakraLink>
                ))}
            </Grid>
        </Container>
    );
}

export default ListContent;