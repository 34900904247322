import React from "react";
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Center,
    Container,
    Flex,
    Image,
    Link as ChakraLink,
    Text
} from "@chakra-ui/react";
import {Form, Formik, FormikHelpers} from "formik";
import {Link as ReactRouterLink, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import lang from "../../../lang.json"
import {getRequiredError, langue, setLocalStorageLogin} from "../../../function/function";
import {createAccount} from "../../../api/user";
import ConnexionTextInput from "../../../component/input/ConnexionTextInput";
import {InputTypeEnum} from "../../../component/componentInterface";
import HideShowPasswordInput from "../../../component/input/HideShowPasswordInput";

export interface CreationAccountFormValues {
    username: string;
    password: string;
    confirmPassword: string;
    /*email: string;*/
}

const CreationAccountPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const username = lang[langue].username;
    //const email = lang[langue].email;
    const password = lang[langue].password;

    const handleCreateAccount = (values: CreationAccountFormValues, actions: FormikHelpers<CreationAccountFormValues>) => {
        if (values.password !== values.confirmPassword) {
            actions.setErrors({
                confirmPassword: lang[langue].passwordAreNotTheSame,
            });
        } else {
            createAccount(values).then((res) => {
                const user = res.data.user as User;

                switch (res.data.status) {
                    case 200:
                        dispatch({type: 'LOGIN', payload: user});
                        setLocalStorageLogin(false, user);
                        navigate("/lists")
                        break;
                    case 201:
                        actions.setErrors({
                            password: lang[langue].explainPassword,
                        });
                        break;
                    case 202:
                        actions.setErrors({
                            username: lang[langue].usernameAlreadyTaken,
                        });
                        break;
                    default:
                        alert("Contact an administrator please");
                        break;
                }
            })
        }

        actions.setSubmitting(false)
    }

    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"}>
            <Center minH={"100vh"}>
                <Flex direction={"column"} width={"560px"}>
                    <Alert status='error' mt={"15px"}>
                        <AlertIcon/>
                        Il s'agit d'une BETA, ne mettez pas de mot de passe sensible.
                        Vous devrez le renouveler plus tard.
                    </Alert>

                    <Box width={"100%"} bg={"white"} borderRadius={"15"} boxShadow={"2xl"} pt={"15px"} pb={"25px"}
                         mt={"10px"} mb={"10px"}>
                        <Flex direction={"column"} gap={"25px"}>
                            <Center>
                                <Flex alignItems={"center"} gap={"10px"}>
                                    <Image src={"/assets/logoBlack.svg"} w={"60px"} h={"60px"}/>
                                    <Text fontSize={"2xl"}>{lang[langue].websiteTitle}</Text>
                                </Flex>
                            </Center>
                            <Center>
                                <Text fontSize={"3xl"} color={"#A78295"}>{lang[langue].createAccount}</Text>
                            </Center>

                            <Formik
                                initialValues={
                                    {
                                        username: "",
                                        password: "",
                                        confirmPassword: "",
                                        /*email: ""*/
                                    }
                                }
                                onSubmit={
                                    (values, actions) => {
                                        handleCreateAccount(values, actions);
                                    }
                                }>
                                {(props) => (
                                    <Form>
                                        <Flex direction={"column"} gap={"20px"} pl={"7%"} pr={"7%"}>
                                            <ConnexionTextInput
                                                type={InputTypeEnum.TEXT}
                                                fieldName={"username"}
                                                label={username}
                                                required={true}
                                                errorFunction={(value) => getRequiredError(username, value)}
                                            />

                                            {/*<ConnexionTextInput
                                                type={InputTypeEnum.EMAIL}
                                                fieldName={"email"}
                                                label={email}
                                                required={true}
                                                errorFunction={(value) => getRequiredError(email, value)}
                                            />*/}


                                            <HideShowPasswordInput<CreationAccountFormValues>
                                                fieldName="password"
                                                label={password}
                                                required={true}
                                                errorFunction={(value) => getRequiredError(password, value)}
                                            />


                                            <HideShowPasswordInput<CreationAccountFormValues>
                                                fieldName="confirmPassword"
                                                label={lang[langue].confirmPassword}
                                                placeholder={password}
                                                required={true}
                                                errorFunction={(value) => getRequiredError(password, value)}
                                            />

                                            <Flex direction={"column"} alignItems={"center"} gap={"10px"} w={"100%"}>
                                                <Button
                                                    minW={"180px"}
                                                    width={"50%"}
                                                    color={"#272626"}
                                                    background={'#B394A4'}
                                                    transition={"all 0.2s ease-in-out"}
                                                    _hover={{
                                                        background: "#A78295",
                                                    }}
                                                    isLoading={props.isSubmitting}
                                                    type='submit'
                                                >
                                                    {lang[langue].createThisAccount}
                                                </Button>

                                                <ChakraLink
                                                    as={ReactRouterLink}
                                                    to='/'
                                                    color={"#A78295"}
                                                    transition={"color 0.3s"}
                                                    fontSize={"17px"}
                                                    _hover={{
                                                        color: "#3F2E3E",
                                                        textDecoration: "none"
                                                    }}
                                                >
                                                    {lang[langue].alreadyHaveAnAccount}
                                                </ChakraLink>
                                            </Flex>
                                        </Flex>
                                    </Form>
                                )}
                            </Formik>
                        </Flex>
                    </Box>
                </Flex>
            </Center>
        </Container>
    )
}

export default CreationAccountPage;