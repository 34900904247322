import React, {FC, useEffect, useState} from "react";
import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle,
    Text,
    useToast
} from "@chakra-ui/react";
import TextInput from "../../../component/input/TextInput";
import {CreateListProps, InputTypeEnum} from "../../../component/componentInterface";
import lang from "../../../lang.json";
import {langue} from "../../../function/function";
import {Select} from "chakra-react-select";
import {GenreOptions} from "../../interface";
import {
    fetchAllGenres,
    fetchGenres,
    fetchListWithPrevious,
    handleSaveFirstPart,
    initEmptyList,
    listSteps
} from "../../fetchActions/list/updateList";
import {selectStyle} from "../../../theme";
import BackHeader from "../../../component/header/BackHeader";
import {useLocation, useNavigate} from "react-router-dom";

const S1CreateList: FC<CreateListProps> = ({headerTitle, previousLink, user}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    const toast = useToast();
    const navigate = useNavigate();
    const [errorName, setErrorName] = useState<boolean>(false)

    //List
    const [previousList, setPreviousList] = useState<List>(initEmptyList(user))
    const [list, setList] = useState<List>(initEmptyList(user))

    //Genres
    const [allGenres, setAllGenres] = useState<Genre[]>([]);
    const [selectedGenres, setSelectedGenres] = useState<readonly GenreOptions[]>([]);
    const [genreOptions, setGenreOptions] = useState<GenreOptions[]>([])

    //UseEffect
    useEffect(() => {
        fetchListWithPrevious(id, user, setList, setPreviousList);
    }, []);

    useEffect(() => {
        fetchAllGenres(setAllGenres)
    }, []);

    useEffect(() => {
        fetchGenres(list, allGenres, setGenreOptions, setSelectedGenres);
    }, [allGenres]);


    //ChangeFunctions
    const handleNameChange = (value: string) => {
        setErrorName(value === "");

        setList({...list, name: value})
    }

    const handleChangeGenre = (value: readonly GenreOptions[]) => {
        const genres: Genre[] = [];

        value.forEach((option) => {
            genres.push(option.genre)
        });

        setSelectedGenres(value)
        setList({...list, genres: genres})
    }


    //Save
    const handleGlobalSave = () => {
        if (list.name === "") {
            setErrorName(true);
            return
        }

        handleSaveFirstPart(previousList, list).then((res) => {
            if (res.status === 400 || res.status === 200) {
                const list = res.list as List;

                if (res.status === 200) {
                    toast({
                        description: lang[langue].successUpdated,
                        status: "success",
                        duration: 2500,
                        isClosable: true,
                    });
                }

                navigate(previousLink + "?id=" + list.id + "&step=2")
            } else {
                toast({
                    description: lang[langue].errorToast,
                    status: "error",
                    duration: 2500,
                    isClosable: true,
                });
            }
        });
    }

    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"} p={"0"}>
            <BackHeader headerTitle={headerTitle} previousLink={"/lists"}/>

            <Center>
                <Flex alignItems={"center"} height={"55px"} transition={"width 0.4s"}
                      width={{base: "100%", sm: "95%", md: "80%", lg: "70%", xl: "50%"}}>
                    <Stepper index={0} colorScheme={"tb"} w={"100%"} p={2}>
                        {listSteps.map((step, index) => (
                            <Step key={index} className={"stepper"}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon/>}
                                        incomplete={<StepNumber/>}
                                        active={<StepNumber/>}
                                    />
                                </StepIndicator>

                                <Box flexShrink='0'>
                                    <StepTitle>{step.title}</StepTitle>
                                    <StepDescription>{step.description}</StepDescription>
                                </Box>

                                <StepSeparator/>
                            </Step>
                        ))}
                    </Stepper>
                </Flex>
            </Center>

            <Center minH={"calc(100vh - 100px - 55px - 50px)"}>
                <Box bg={"#A78295"} transition={"width 0.4s"} p={"25px"}
                     borderRadius={"15px"} boxShadow={"dark-lg"}
                     width={{base: "98%", sm: "90%", md: "60%", lg: "50%", xl: "35%"}}>
                    <Flex direction={"column"} gap={"30px"}>
                        <FormControl isRequired={true}>
                            <FormLabel requiredIndicator={"*"}>{lang[langue].listName}</FormLabel>
                            <TextInput
                                type={InputTypeEnum.TEXT}
                                label={lang[langue].listName}
                                value={list.name}

                                isRequired={true}
                                isInvalid={errorName}

                                onChange={(value) => handleNameChange(value)}
                                onBlur={(value) => {
                                    setErrorName(value === "");
                                }}
                            />
                            <Text hidden={!errorName} color={"darkred"}
                                  mt={"2px"}>{lang[langue].listName + " " + lang[langue].isRequired}</Text>
                        </FormControl>

                        <FormControl>
                            <FormLabel>{lang[langue].selectGenreTitle}</FormLabel>
                            <Select
                                isMulti
                                variant={"custom"}
                                name="genres"
                                options={genreOptions}
                                placeholder={lang[langue].selectGenre}
                                closeMenuOnSelect={false}
                                value={selectedGenres}
                                onChange={(value) => handleChangeGenre(value)}
                                colorScheme={"tb"}
                                chakraStyles={selectStyle.chakraStyles}
                            />
                        </FormControl>
                    </Flex>
                </Box>
            </Center>

            <Flex justifyContent={"end"} h={"50px"} pr={2} pl={2}>
                <Button colorScheme={"tb"} onClick={handleGlobalSave}>
                    {lang[langue].next}
                </Button>
            </Flex>
        </Container>
    )
};

export default S1CreateList;