import {InputPropsWithType} from "../componentInterface";
import React from "react";
import {Field, FieldProps} from "formik";
import {validateField} from "../../function/function";
import {FormControl, FormErrorMessage, FormLabel, Input, InputGroup} from "@chakra-ui/react";

function ConnexionTextInput<T>({fieldName, label, placeholder, required, errorFunction, type}: InputPropsWithType) {
    return (
        <Field name={fieldName}
               validate={errorFunction ? (value: string) => validateField(value, fieldName, errorFunction) : null}>
            {({field, form}: FieldProps<string, T>) => (
                <FormControl isRequired={required}
                             isInvalid={!!(form.errors[fieldName as keyof T] && form.touched[fieldName as keyof T])}>
                    <FormLabel>{label}</FormLabel>
                    <InputGroup>
                        <Input
                            {...field}
                            w={"100%"}
                            type={type}
                            borderColor={"darkgray"}
                            placeholder={placeholder ? placeholder : label}
                        />
                    </InputGroup>

                    <FormErrorMessage>{form.errors[fieldName as keyof T] as string}</FormErrorMessage>
                </FormControl>
            )}
        </Field>
    );
}

export default ConnexionTextInput;