import axios, {AxiosResponse} from "axios";
import {CreationAccountFormValues} from "../views/pages/connexion/CreationAccountPage";
import {baseURL} from "../function/function";

export const connect = async (username: string, password: string): Promise<AxiosResponse<UserApiDataType>> => {
    try {
        const connectUserInformation: Pick<User, "username" | "password"> = {
            username: username,
            password: password
        };

        return await axios.post(
            `${baseURL}/user-connect`,
            connectUserInformation,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<UserApiDataType>;
    } catch (e) {
        throw new Error("Error while connecting user : " + e)
    }
}

export const createAccount = async (values: CreationAccountFormValues): Promise<AxiosResponse<UserApiDataType>> => {
    try {
        const createAccountUserInformation: Pick<User, "username" | "password" /*| "email"*/> = {
            username: values.username,
            password: values.password,
            //email: values.email
        };

        return await axios.post(
            `${baseURL}/add-user`,
            createAccountUserInformation,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<UserApiDataType>;
    } catch (e) {
        throw new Error("Error while creating account : " + e)
    }
}

export const findUserByID = async (id: string): Promise<AxiosResponse<UserApiDataType>> => {
    try {
        return await axios.get(
            `${baseURL}/user/id/${id}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<UserApiDataType>;
    } catch (e) {
        throw new Error("Error while retrieving the user : " + e)
    }
}