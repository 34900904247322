import React, {FC, useEffect, useState} from "react";
import {
    Box,
    Button,
    Center,
    CloseButton,
    Container,
    Flex,
    Heading,
    HStack,
    Image,
    Input,
    Tag,
    Text,
    useDisclosure,
    useToast,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {MdOutlineAddBox} from "react-icons/md";
import {RootState} from "../../../redux/store";
import {baseURL, langue} from "../../../function/function";
import lang from "../../../lang.json"
import BackHeader from "../../../component/header/BackHeader";
import {InputTypeEnum} from "../../../component/componentInterface";
import ModalAddBookList from "./ModalAddBookList";
import {fetchBook, handleSave, initEmptyUserBook} from "../../fetchActions/book/bookDetails";

const BookDetails: FC = () => {
    const user = useSelector((state: RootState) => state.auth.user) as User;
    const toast = useToast()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {id} = useParams()

    const [modifiedKey, setModifiedKey] = useState<string>("");
    const [changed, setChanged] = useState(false)
    const [book, setBook] = useState<UserBook>(initEmptyUserBook)

    //UseEffect
    useEffect(() => {
        if (id) {
            fetchBook(id, user, setBook)
        }
    }, []);


    useEffect(() => {
        if (changed) {
            const timer = setTimeout(() => handleSave(toast, user, book, modifiedKey), 500);
            return () => clearTimeout(timer);
        }
    }, [book]);


    //Handle
    const handleChange = (value: string, dateKey: keyof Pick<UserBook, "started" | "finished">) => {
        let resultValue: string | null = value
        if (resultValue === "") {
            resultValue = null
        }

        setModifiedKey(dateKey)
        setChanged(true)
        setBook((prevBook) => ({
            ...prevBook,
            [dateKey]: resultValue,
        }));
    }

    const handleReset = (dateKey: keyof Pick<UserBook, "started" | "finished">) => {
        setModifiedKey(dateKey)
        setChanged(true)

        setBook((prevBook) => ({
            ...prevBook,
            [dateKey]: null,
        }));
    }


    return (
        <Container minH={"100vh"} h={"auto"} maxW={"100%"} bg={"#EFE1D1"} p={"0"}>
            <BackHeader headerTitle={lang[langue].bookPageTitle} previousLink={"/lists"}/>

            <Center pr={2} pl={2} minH={"calc(100vh - 100px - 70px)"}>
                <Box bg={"#A78295"} transition={"width 0.4s"} mt={"10px"}
                     borderRadius={"15px"} boxShadow={"dark-lg"}
                     width={{base: "100%", sm: "90%", md: "80%", lg: "80%", xl: "55%"}}>
                    <Flex flexDirection={{base: "column", xl: "row"}} gap={"20px"}
                          p={"20px"}>
                        <Flex justifyContent={"center"} alignItems={"center"} minW={"300px"} minH={"280px"}
                              maxH={"480px"}>
                            <Image src={baseURL + "/" + book.image.path} maxH={"480px"} borderRadius={"5px"}/>
                        </Flex>

                        <Flex flexDirection={"column"} gap={"20px"}>
                            <Heading>{book.title}</Heading>

                            <Flex flexDirection={"column"} gap={"3px"}>
                                {
                                    book.author ?
                                        <HStack>
                                            <Text alignSelf={"self-start"} fontWeight={"bold"}
                                                  fontSize={"19px"}>{lang[langue].author} : </Text>
                                            <Text
                                                alignSelf={"self-end"}
                                                fontSize={"17px"}>{book.author.firstName + " " + book.author.lastName}</Text>
                                        </HStack> : null
                                }

                                {
                                    book.editor ?
                                        <HStack>
                                            <Text alignSelf={"self-start"} fontWeight={"bold"}
                                                  fontSize={"17px"}>{lang[langue].editor} : </Text>
                                            <Text alignSelf={"self-end"}>{book.editor.name}</Text>
                                        </HStack> : null
                                }

                                {
                                    book.releaseDate ?
                                        <HStack>
                                            <Text alignSelf={"self-start"} fontWeight={"bold"}
                                                  fontSize={"17px"}>{lang[langue].publicationDate} : </Text>
                                            <Text
                                                alignSelf={"self-end"}>{dayjs(book.releaseDate).format("MM/YYYY")}</Text>
                                        </HStack> : null
                                }
                            </Flex>

                            <Wrap>
                                {book.genres.map((genre: Genre) => (
                                    <WrapItem key={genre.id}>
                                        <Tag background={"#3F2E3E"} color={"white"}
                                             fontWeight={"unset"} size={"lg"}>{genre.name}</Tag>
                                    </WrapItem>
                                ))}
                            </Wrap>

                            <Text textAlign={"justify"}>{book.description}</Text>

                            <Flex flexDirection={"column"} gap={"10px"}>
                                <Flex gap={"5px"}>
                                    <Text fontWeight={"bold"}
                                          fontSize={"17px"} w={"100px"}>{lang[langue].startDate}</Text>

                                    <Input width={"180px"} type={InputTypeEnum.DATE}
                                           borderColor={"black"}
                                           focusBorderColor={"black"}
                                           _hover={{
                                               borderColor: "black"
                                           }}
                                           onChange={(e) => {
                                               handleChange(e.target.value, "started")
                                           }}
                                           value={book.started || ""}
                                    />

                                    <CloseButton hidden={!book.started} size='lg'
                                                 onClick={() => handleReset("started")}/>
                                </Flex>

                                <Flex gap={"5px"}>
                                    <Text fontWeight={"bold"}
                                          fontSize={"17px"} w={"100px"}>{lang[langue].endDate}</Text>

                                    <Input width={"180px"} type={InputTypeEnum.DATE}
                                           borderColor={"black"}
                                           focusBorderColor={"black"}
                                           _hover={{
                                               borderColor: "black"
                                           }}
                                           onChange={(e) => {
                                               handleChange(e.target.value, "finished")
                                           }}
                                           value={book.finished || ""}
                                    />

                                    <CloseButton hidden={!book.finished} size='lg'
                                                 onClick={() => handleReset("finished")}/>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Box>
            </Center>

            <Center pb={"10px"} mt={"20px"}>
                <Flex justifyContent={'end'} transition={"width 0.4s"}
                      width={{base: "100%", sm: "90%", md: "80%", lg: "80%", xl: "95%"}} pl={2} pr={2}>
                    <Button leftIcon={<MdOutlineAddBox size={20}/>} colorScheme='tb' color={"black"} onClick={onOpen}>
                        {lang[langue].addBookToList}
                    </Button>
                </Flex>
            </Center>

            <ModalAddBookList idBook={book.id} isOpen={isOpen} onClose={onClose} opened={isOpen} toast={toast}/>
        </Container>
    )
}

export default BookDetails;