import React from 'react';
import {Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement} from '@chakra-ui/react';
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';
import {Field, FieldProps} from "formik";
import {InputProps, InputTypeEnum} from "../componentInterface";
import {validateField} from "../../function/function";

function HideShowPasswordInput<T>({fieldName, label, placeholder, required, errorFunction}: InputProps) {
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    return (
        <Field name={fieldName}
               validate={errorFunction ? (value: string) => validateField(value, fieldName, errorFunction) : null}>
            {({field, form}: FieldProps<string, T>) => (
                <FormControl isRequired={required}
                             isInvalid={!!(form.errors[fieldName as keyof T] && form.touched[fieldName as keyof T])}>
                    <FormLabel>{label}</FormLabel>
                    <InputGroup>
                        <Input
                            {...field}
                            w={"100%"}
                            type={show ? InputTypeEnum.TEXT : InputTypeEnum.PASSWORD}
                            borderColor={"darkgray"}
                            placeholder={placeholder ? placeholder : label}
                        />
                        <InputRightElement w={"4rem"}>
                            <Button h="1.75rem" background={"transparent"} onClick={handleClick}>
                                {show ? <FaRegEyeSlash size={20}/> : <FaRegEye size={20}/>}
                            </Button>
                        </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>{form.errors[fieldName as keyof T] as string}</FormErrorMessage>
                </FormControl>
            )}
        </Field>
    );
}

export default HideShowPasswordInput;