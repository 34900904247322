import React from "react";
import S1CreateList from "./S1CreateList";
import {useLocation} from "react-router-dom";
import S2CreateList from "./S2CreateList";
import S3CreateList from "./S3CreateList";
import {RootState} from "../../../redux/store";
import {useSelector} from "react-redux";
import {HeaderGeneralProps} from "../../../component/componentInterface";

const CreateListPage = ({headerTitle, previousLink}: HeaderGeneralProps) => {
    const user = useSelector((state: RootState) => state.auth.user) as User;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const step = searchParams.get('step');

    switch (step) {
        case '2':
            return <S2CreateList headerTitle={headerTitle} previousLink={previousLink} user={user}/>;
        case '3':
            return <S3CreateList headerTitle={headerTitle} previousLink={previousLink} user={user}/>;
        default:
            return <S1CreateList headerTitle={headerTitle} previousLink={previousLink} user={user}/>;
    }
}

export default CreateListPage;