import React, { FC } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

interface LimitedTextProps extends TextProps {
    maxWords: number;
}

const LimitedText: FC<LimitedTextProps> = ({ children, maxWords, ...rest }) => {
    const words = children?.toString().split(/\s+/).slice(0, maxWords);
    const truncatedText = words?.join(' ');

    return <Text textAlign="justify"  {...rest}>{truncatedText}...</Text>;
};

export default LimitedText;
