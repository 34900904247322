import axios, {AxiosResponse} from "axios";
import {baseURL} from "../function/function";

export const getAllGenre = async (): Promise<AxiosResponse<GenreApiDataType>> => {
    try {
        return await axios.get(
            baseURL + `/genres`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<GenreApiDataType>;
    } catch (e) {
        throw new Error("Error while getting genre: " + e)
    }
}

export const getGenreByID = async (id: string): Promise<AxiosResponse<GenreApiDataType>> => {
    try {
        return await axios.get(
            baseURL + `/genre/${id}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<GenreApiDataType>;
    } catch (e) {
        throw new Error("Error while getting genre: " + e)
    }
}