import React from "react";
import {Box, Flex, Link as ChakraLink, Text} from "@chakra-ui/react";
import {MdArrowBackIos} from "react-icons/md";
import {Link as ReactRouterLink, useNavigate} from "react-router-dom";
import {HeaderGeneralProps} from "../componentInterface";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

function BackHeader(props: HeaderGeneralProps) {
    const navigate = useNavigate();

    const auth = useSelector((state: RootState) => state.auth);
    if (!auth.isAuthenticated) {
        navigate("/")
    }

    return (
        <Box width={"100%"} height={"100px"} bg={"#331D2C"}>
            <Flex alignItems={"center"} height={"100%"} p={"25px"} justifyContent={"space-between"}>
                <ChakraLink as={ReactRouterLink} to={props.previousLink} className={"iconBack"} color={"white"}>
                    <MdArrowBackIos size={30}/>
                </ChakraLink>
                <Text color={"white"} fontSize={"30px"}>{props.headerTitle}</Text>
                <Box bg={"transparent"} w={"30px"}/>
            </Flex>
        </Box>
    )
}

export default BackHeader