import {AddIcon, CheckIcon} from "@chakra-ui/icons";
import {IconButton} from "@chakra-ui/react";
import React from "react";

interface CheckButtonInterface {
    colorScheme: string
    onClick: () => void
}

export function AddButton({colorScheme, onClick}: CheckButtonInterface) {
    return (
        <IconButton
            className={"checkIcon"}
            isRound={true}
            border={"solid 1px green"}
            colorScheme={colorScheme}
            aria-label='Done'
            fontSize='15px'
            minW={"30px"}
            h={"30px"}
            icon={<AddIcon/>}
            onClick={onClick}
        />
    )
}

export function CheckButton({colorScheme, onClick}: CheckButtonInterface) {
    return (
        <IconButton
            className={"checkIcon"}
            isRound={true}
            border={"solid 1px green"}
            colorScheme={colorScheme}
            aria-label='Done'
            fontSize='15px'
            minW={"30px"}
            h={"30px"}
            icon={<CheckIcon/>}
            onClick={onClick}
        />
    )
}