import {InputPropsWithType} from "../componentInterface";
import {Field, FieldProps} from "formik";
import {validateField} from "../../function/function";
import {FormControl, FormErrorMessage, FormLabel, Input, InputGroup} from "@chakra-ui/react";
import React from "react";

function FormikTextInput<T>({fieldName, label, placeholder, required, errorFunction, type, disabled}: InputPropsWithType) {
    return (
        <Field name={fieldName}
               validate={errorFunction ? (value: string) => validateField(value, fieldName, errorFunction) : null}>
            {({field, form}: FieldProps<string, T>) => (
                <FormControl isRequired={required}
                             isInvalid={!!(form.errors[fieldName as keyof T] && form.touched[fieldName as keyof T])}>
                    <FormLabel>{label}</FormLabel>
                    <InputGroup>
                        <Input
                            {...field}
                            className={"input"}
                            disabled={disabled}

                            w={"100%"}
                            type={type}
                            placeholder={placeholder ? placeholder : label}

                            border={"solid 1.5px #4E2C43"}
                            errorBorderColor={"darkred"}

                            _hover={{borderColor: "tb.900"}}
                            _placeholder={{color: "rgba(63, 63, 63, 0.8)"}}
                        />
                    </InputGroup>

                    <FormErrorMessage color={"darkred"}>{form.errors[fieldName as keyof T] as string}</FormErrorMessage>
                </FormControl>
            )}
        </Field>
    );
}

export default FormikTextInput;