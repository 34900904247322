interface AuthState {
    isAuthenticated: boolean;
    user: User | null;
}

interface AuthAction {
    type: string;
    payload?: User;
}

const authReducer = (state: AuthState = { isAuthenticated: false, user: null }, action: AuthAction): AuthState => {
    switch (action.type) {
        case 'LOGIN':
            return { isAuthenticated: true, user: action.payload || null };
        case 'LOGOUT':
            return { isAuthenticated: false, user: null };
        default:
            return state;
    }
};

export default authReducer;
