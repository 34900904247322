import React, {FC} from "react";
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Center,
    Container,
    Flex,
    Image,
    Link as ChakraLink,
    Text
} from "@chakra-ui/react";
import {Form, Formik, FormikHelpers} from "formik";
import {Link as ReactRouterLink, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import lang from "../../../lang.json"
import {getRequiredError, langue, setLocalStorageLogin} from "../../../function/function";
import {connect} from "../../../api/user";
import ConnexionTextInput from "../../../component/input/ConnexionTextInput";
import {InputTypeEnum} from "../../../component/componentInterface";
import HideShowPasswordInput from "../../../component/input/HideShowPasswordInput";

interface ConnexionFormValues {
    username: string;
    password: string;
    rememberMe: boolean;
}

const ConnexionPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Variables de lang
    const title = lang[langue].websiteTitle;
    const connection = lang[langue].connectionTitle;
    const username = lang[langue].username;
    const password = lang[langue].password;

    const handleLogin = (values: ConnexionFormValues, actions: FormikHelpers<ConnexionFormValues>) => {
        connect(values.username, values.password).then((res) => {
            const errorConnexion = lang[langue].errorConnexion
            const user = res.data.user as User;

            switch (res.data.status) {
                case 200:
                    dispatch({type: 'LOGIN', payload: user});
                    setLocalStorageLogin(true, user);
                    navigate(`/lists`);
                    break;
                case 404:
                case 201:
                    actions.setErrors({
                        password: errorConnexion,
                    });
                    break;
                default:
                    alert("Contact an administrator please");
                    break;
            }

            actions.setSubmitting(false)
        });
    };

    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"}>
            <Center minH={"100vh"}>
                <Flex direction={"column"} width={"560px"}>
                    <Alert status='error' mt={"15px"}>
                        <AlertIcon/>
                        Il s'agit d'une BETA, ne mettez pas de mot de passe sensible.
                        Vous devrez le renouveler plus tard.
                    </Alert>

                    <Box width={"100%"} bg={"white"} borderRadius={"15"} boxShadow={"2xl"} pt={"15px"} pb={"35px"}
                         mt={"10px"} mb={"10px"}>
                        <Flex direction={"column"} gap={"30px"}>
                            <Center>
                                <Flex alignItems={"center"} gap={"10px"}>
                                    <Image src={"/assets/logoBlack.svg"} w={"60px"} h={"60px"}/>
                                    <Text fontSize={"2xl"}>{title}</Text>
                                </Flex>
                            </Center>
                            <Center>
                                <Text fontSize={"3xl"}>{connection}</Text>
                            </Center>
                            <Formik
                                initialValues={
                                    {
                                        username: "",
                                        password: "",
                                        rememberMe: false
                                    }
                                }
                                onSubmit={
                                    (values, actions) => {
                                        handleLogin(values, actions)
                                    }
                                }>
                                {(props) => (
                                    <Form>
                                        <Flex direction={"column"} gap={"20px"} pl={"7%"} pr={"7%"}>
                                            <ConnexionTextInput<ConnexionFormValues>
                                                type={InputTypeEnum.TEXT}
                                                fieldName={"username"}
                                                label={username}
                                                required={true}
                                                errorFunction={(value) => getRequiredError(username, value)}
                                            />

                                            <HideShowPasswordInput<ConnexionFormValues>
                                                fieldName="password"
                                                label={password}
                                                required={true}
                                                errorFunction={(value) => getRequiredError(password, value)}
                                            />

                                            <Flex direction={"column"} alignItems={"center"} gap={"10px"} w={"100%"}
                                                  mt={"30px"}>
                                                <Button
                                                    minW={"180px"}
                                                    width={"50%"}
                                                    color={"#272626"}
                                                    background={'#B394A4'}
                                                    transition={"all 0.2s ease-in-out"}
                                                    _hover={{
                                                        background: "#A78295",
                                                    }}
                                                    isLoading={props.isSubmitting}
                                                    type='submit'
                                                >
                                                    {lang[langue].connexion}
                                                </Button>

                                                <ChakraLink
                                                    as={ReactRouterLink}
                                                    to='/createAccount'
                                                    color={"#A78295"}
                                                    transition={"color 0.3s"}
                                                    fontSize={"17px"}
                                                    _hover={{
                                                        color: "#3F2E3E",
                                                        textDecoration: "none"
                                                    }}
                                                >
                                                    {lang[langue].doNotHaveAccount}
                                                </ChakraLink>

                                                <ChakraLink hidden={true}
                                                    as={ReactRouterLink}
                                                    to='/passwordForgotten'
                                                    color={"#A78295"}
                                                    transition={"color 0.3s"}
                                                    fontSize={"17px"}
                                                    _hover={{
                                                        color: "#3F2E3E",
                                                        textDecoration: "none"
                                                    }}
                                                >
                                                    {lang[langue].doNotKnowPass}
                                                </ChakraLink>
                                            </Flex>
                                        </Flex>
                                    </Form>
                                )}
                            </Formik>
                        </Flex>
                    </Box>
                </Flex>
            </Center>
        </Container>
    );
}

export default ConnexionPage;