import axios, {AxiosResponse} from "axios";
import {baseURL} from "../function/function";

export const getAllBooks = async (): Promise<AxiosResponse<BookApiDataType>> => {
    try {
        return await axios.get(
            `${baseURL}/books`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<BookApiDataType>;
    } catch (e) {
        throw new Error("Error while retrieving books : " + e)
    }

}

export const getAllBooksFilterByName = async (filter: string): Promise<AxiosResponse<BookApiDataType>> => {
    try {
        return await axios.get(
            `${baseURL}/books/title/${filter}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<BookApiDataType>;
    } catch (e) {
        throw new Error("Error while retrieving books : " + e)
    }
}

export const getBookByID = async (id: string): Promise<AxiosResponse<BookApiDataType>> => {
    try {
        return await axios.get(
            `${baseURL}/book/${id}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<BookApiDataType>;
    } catch (e) {
        throw new Error("Error while retrieving the book : " + e)
    }
}

export const getUserBookByID = async (bookID: string, userID: string): Promise<AxiosResponse<UserBookApiDataType>> => {
    try {
        return await axios.get(
            `${baseURL}/userBook/${bookID}/user/${userID}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<UserBookApiDataType>;
    } catch (e) {
        throw new Error("Error while retrieving the book : " + e)
    }
}

export const updateUserBook = async (userID: string, book: UserBook, value: string): Promise<AxiosResponse<BookApiDataType>> => {
    try {
        return await axios.put(
            `${baseURL}/user/${userID}/book/${book.id}/${value}`,
            book,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<BookApiDataType>;
    } catch (e) {
        throw new Error("Error while updating the book : " + e)
    }
}

export const addBook = async (book: Book): Promise<AxiosResponse<BookApiDataType>> => {
    return await axios.post(
        `${baseURL}/add-book`,
        book,
        {headers: {'Content-Type': 'application/json'}}
    ) as AxiosResponse<BookApiDataType>;
}