import lang from '../lang.json';
import {ValidationFunction} from "../component/componentInterface";
import {Dispatch} from "redux";

export const langue = "fr";

export const baseURL = "https://ourLibrary.fr";

export function validateField(value: string, key: string, validationFunction: ValidationFunction): string | undefined {
    return validationFunction(value, key);
}

export function getRequiredError(key: string, value: string): string | undefined {
    let error;

    if (!value) {
        error = key + " " + lang[langue].isRequired;
    }

    return error;
}

export function setLocalStorageLogin(rememberMe: boolean, user: User) {
    const sessionExpiration = 60 * 60 * 1000; // 1 heure
    const rememberMeExpiration = 30 * 24 * 60 * 60 * 1000; // 30 jours

    const date = Date.now();
    const expiration = rememberMe ? date + rememberMeExpiration : date + sessionExpiration;

    localStorage.setItem('user', JSON.stringify({user: user, expiration: expiration}));
}

export function checkIfGenresAreEqual(previousGenre: Genre[], genres: Genre[]): boolean {
    if (previousGenre.length !== genres.length) {
        return false;
    }

    const sortedPreviousGenres = previousGenre.sort();
    const sortedGenres = genres.sort();

    for (let i = 0; i < sortedPreviousGenres.length; i++) {
        if (sortedPreviousGenres[i].id !== sortedGenres[i].id) {
            return false;
        }
    }

    return true;
}

export const logout = (dispatch: Dispatch) => {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('user');
};