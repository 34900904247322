import axios, {AxiosResponse} from "axios";
import {baseURL} from "../function/function";

export const getAllListsByUser = async (id: string): Promise<AxiosResponse<ListApiDataType>> => {
    try {
        return await axios.get(
            baseURL + `/lists/${id}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    } catch (e) {
        throw new Error("Error while resolving lists : " + e)
    }
}

export const getListByID = async (id: string): Promise<AxiosResponse<ListApiDataType>> => {
    try {
        return await axios.get(
            baseURL + `/list/${id}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    } catch (e) {
        throw new Error("Error while resolving list : " + e)
    }
}

export const getListByUserAndID = async (userId: string, id: string): Promise<AxiosResponse<ListApiDataType>> => {
    try {
        return await axios.get(
            baseURL + `/list/user/${userId}/list/${id}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    } catch (e) {
        throw new Error("Error while resolving list : " + e)
    }
}

export const updateListByID = async (list: List): Promise<AxiosResponse<ListApiDataType>> => {
    if (list.id !== "") {
        return await axios.put(
            baseURL + `/update-list/${list.id}`,
            list,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    } else {
        return await axios.post(
            baseURL + `/create-list`,
            list,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    }
}

export const deleteListByID = async (id: string): Promise<AxiosResponse<ListApiDataType>> => {
    try {
        return await axios.delete(
            baseURL + `/delete-list/${id}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    } catch (e) {
        throw new Error("Error while connecting user : " + e)
    }
}


//List Book
export const addBookToList = async (idList: string, idBook: string): Promise<AxiosResponse<ListApiDataType>> => {
    try {
        return await axios.post(
            baseURL + `/add-list-book/${idList}/${idBook}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    } catch (e) {
        throw new Error("Error while adding the book inside lists : " + e)
    }
}

export const removeBookToList = async (idList: string, idBook: string): Promise<AxiosResponse<ListApiDataType>> => {
    try {
        return await axios.delete(
            baseURL + `/remove-list-book/${idList}/${idBook}`,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    } catch (e) {
        throw new Error("Error while removing the book inside lists : " + e)
    }
}

//List Genre
export const updateListGenre = async (list: List): Promise<AxiosResponse<ListApiDataType>> => {
    try {
        return await axios.put(
            baseURL + `/update-list-genre`,
            list,
            {headers: {'Content-Type': 'application/json'}}
        ) as AxiosResponse<ListApiDataType>;
    } catch (e) {
        throw new Error("Error while updating the genre inside lists : " + e)
    }
}