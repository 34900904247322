import {SelectInputProps} from "../componentInterface";
import {Select} from "@chakra-ui/react";
import React from "react";

function SelectInput({placeholder, onChange, name, children}: SelectInputProps) {
    return (
        <Select
            name={name}
            className={"input"}

            w={"100%"}
            border={"solid 1.5px #4E2C43"}
            placeholder={placeholder ?? ""}

            _hover={{borderColor: "tb.900"}}
            _placeholder={{color: "rgba(63, 63, 63, 0.8)"}}

            onChange={(event) => onChange ? onChange(event.target.value) : null}
        >
            {children}
        </Select>
    )
}

export default SelectInput;