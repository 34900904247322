import React, {FC, useEffect, useState} from "react";
import Home from "../lists/Home";
import {
    Button,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftElement,
    useToast
} from "@chakra-ui/react";
import BackHeader from "../../../component/header/BackHeader";
import {useNavigate, useParams} from "react-router-dom";
import {IoSearch} from "react-icons/io5";
import lang from "../../../lang.json";
import {baseURL, langue} from "../../../function/function";
import {AddButton, CheckButton} from "../../../component/button/Button";
import {getBooks, handleSaveLastPart, initEmptyList} from "../../fetchActions/list/updateList";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {getListByUserAndID} from "../../../api/list";

const AddBooksToList: FC = () => {
    const {id} = useParams();
    const [books, setBooks] = useState<Book[]>([]);
    const [search, setSearch] = useState<string>("");
    const user = useSelector((state: RootState) => state.auth.user) as User;

    const navigate = useNavigate();
    const toast = useToast();

    //List
    const [previousList, setPreviousList] = useState<List>(initEmptyList(user))
    const [list, setList] = useState<List>(initEmptyList(user))


    const handleSearch = (search: string) => {
        setSearch(search)
    }

    const handleRemove = (book: Book) => {
        let updatedList = [...list.books];
        let index = updatedList.map(book => book.id).indexOf(book.id);

        if (index !== -1) {
            updatedList.splice(index, 1);
            setList({...list, books: updatedList})
        }
    }

    const handleAdd = (book: Book) => {
        setList({...list, books: [...list.books, book]});
    };

    useEffect(() => {
        getBooks(search, setBooks)
    }, [search]);

    useEffect(() => {
        let list = initEmptyList(user)

        if (id) {
            getListByUserAndID(user.id, id).then((res) => {
                if (res.data.status === 200) {
                    list = res.data.list as List
                }

                setPreviousList(list)
                setList(list)
            })
        } else {
            setList(list)
        }
    }, []);

    const handleSave = () => {
        handleSaveLastPart(previousList, list).then((res) => {
            if (res.status === 400 || res.status === 200) {
                if (res.status === 200) {
                    toast({
                        description: lang[langue].successUpdated,
                        status: "success",
                        duration: 2500,
                        isClosable: true,
                    });
                }

                navigate(`/lists/content/${id}`)
            } else {
                toast({
                    description: lang[langue].errorToast,
                    status: "error",
                    duration: 2500,
                    isClosable: true,
                });
            }
        });
    }


    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"} p={"0"}>
            <BackHeader previousLink={"/lists/content/" + id} headerTitle={lang[langue].addBookTitle}/>
            <Flex flexDirection={"column"} w={"100%"} p={2} minH={"calc(100vh - 100px - 50px)"}>
                <InputGroup maxW={"400px"}>
                    <InputLeftElement>
                        <IoSearch/>
                    </InputLeftElement>
                    <Input type='text' variant={"custom"} placeholder={lang[langue].researchBook}
                           onChange={(event) => handleSearch(event.target.value)}/>
                </InputGroup>

                <Grid mt={4} gridGap={"10px"} justifyItems={"center"} templateColumns={{
                    base: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(4, 1fr)',
                    lg: 'repeat(5, 1fr)',
                    xl: 'repeat(6, 1fr)'
                }}>
                    {books.map((book) => (
                        <Center maxW={"300px"}>
                            <GridItem key={book.id}>
                                <div className="card">
                                    <div className="image-box">
                                        {<img src={baseURL + "/" + book.image?.path} alt={"Image"}/>}
                                    </div>
                                    <div className="text-box">
                                        <div className="main">
                                            <span>{book.title}</span>
                                        </div>

                                        <div className={"bar"}></div>

                                        <div className="sub">
                                            <span>{book.author ? book.author.firstName + " " + book.author.lastName.toUpperCase() : ""}</span>
                                        </div>
                                    </div>

                                    {list.books.map(book => book.id).includes(book.id) ?
                                        <CheckButton colorScheme={"teal"} onClick={() => handleRemove(book)}/> :
                                        <AddButton colorScheme={"gray"} onClick={() => handleAdd(book)}/>
                                    }
                                </div>
                            </GridItem>
                        </Center>
                    ))}
                </Grid>
            </Flex>
            <Flex justifyContent={"end"} h={"50px"} pr={2} pl={2}>
                <Button colorScheme={"green"} onClick={handleSave}>
                    {lang[langue].validate}
                </Button>
            </Flex>
        </Container>
    )

}

export default AddBooksToList;