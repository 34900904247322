import {Box, Flex, Image, Link, Text} from "@chakra-ui/react";
import React from "react";
import {langue, logout} from "../../function/function";
import lang from '../../lang.json';
import {GiBookmarklet} from "react-icons/gi";
import {TiHome} from "react-icons/ti";
import {CgProfile} from "react-icons/cg";
import {TbDoorExit} from "react-icons/tb";
import {RootState} from "../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

function HeaderGeneral() {
    const show = true;
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const auth = useSelector((state: RootState) => state.auth);
    if (!auth.isAuthenticated) {
        navigate("/")
    }

    // Variables de lang
    const title = lang[langue].websiteTitle

    return (
        <Box width={"100%"} height={"100px"} bg={"#331D2C"}>
            <Flex alignItems={"center"} height={"100%"} p={"25px"} justifyContent={"space-between"}>
                <Flex gap={"30px"} alignItems={"center"}>
                    <Image src={"/assets/logo2.svg"} w={"80px"} h={"80px"}/>
                    <Text fontSize={"2xl"} color={"white"}>{title}</Text>
                </Flex>

                <Flex gap={"30px"}>
                    <Link className={"icon"}
                          color={"white"}
                          _hover={{
                              color: "#A78295",
                          }}
                    >
                        {show ? <TiHome size={30}/> : <GiBookmarklet size={30}/>}
                    </Link>

                    <Link className={"icon"}
                          color={"white"}
                          _hover={{
                              color: "#A78295",
                          }}
                    >
                        <CgProfile size={30}/>
                    </Link>

                    <TbDoorExit size={30} cursor={"pointer"}
                                className={"icon iconRed"}
                                onClick={() => {
                                    logout(dispatch);
                                }}
                    />
                </Flex>
            </Flex>
        </Box>
    )
}

export default HeaderGeneral