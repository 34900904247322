import React, {FC, useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Center,
    Container,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Image,
    useToast
} from "@chakra-ui/react";
import {initEmptyBook} from "../../fetchActions/book/bookDetails";
import {InboxOutlined} from "@ant-design/icons";
import lang from "../../../lang.json";
import {baseURL, getRequiredError, langue} from "../../../function/function";
import {Upload, UploadProps} from "antd";
import BackHeader from "../../../component/header/BackHeader";
import {InputTypeEnum} from "../../../component/componentInterface";
import {Form, Formik} from "formik";
import FormikTextInput from "../../../component/input/FormikTextInput";
import FormikTextArea from "../../../component/input/FormikTextArea";
import {initEmptyImage} from "../../fetchActions/image/fetch";
import dayjs from "dayjs";
import {deleteGlobalImage} from "../../../api/image";
import {addBook} from "../../../api/book";
import {useNavigate} from "react-router-dom";
import {Select} from "chakra-react-select";
import {selectStyle} from "../../../theme";
import {GenreOptions} from "../../interface";
import {getAllGenre} from "../../../api/genre";

interface CreateBookInterface {
    title: "",
    authorFirstName: "",
    authorLastName: "",
    releaseDate: "",
    editorName: "",
    description: "",
}

const CreateBook: FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [book, setBook] = useState<Book>(initEmptyBook)
    const [save, setSave] = useState(false)

    //Genres
    const [genreOptions, setGenreOptions] = useState<GenreOptions[]>([])
    const [selectedGenres, setSelectedGenres] = useState<readonly GenreOptions[]>([]);

    const {Dragger} = Upload;
    const props: UploadProps = {
        name: 'file',
        action: baseURL + '/uploadImage/',
        accept: '.jpg,.png,.jpeg',
        maxCount: 1,
        showUploadList: false,

        onChange(info) {
            const {status} = info.file;

            if (status === 'done') {
                const image = info.file.response.image as Image

                setBook({
                    ...book,
                    image: image
                });
            } else if (status === 'error') {
                toast({
                    description: lang[langue].errorToast,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            }
        },

        onDrop() {
        }
    };

    useEffect(() => {
        if (save) {
            if (!book.image.id) {
                toast({
                    description: lang[langue].imageMandatory,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });

                setSave(false);
            } else {
                addBook(book).then((res) => {
                    if (res.status === 200) {
                        toast({
                            description: lang[langue].bookCreated,
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                        });

                        navigate("/lists/")
                    } else {
                        toast({
                            description: lang[langue].errorToast,
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                        });
                    }
                });
            }
        }
    }, [book, save]);

    useEffect(() => {
        getAllGenre().then((res) => {
            const genreSelect: GenreOptions[] = [];

            if (res.data.status === 200) {
                const genres = res.data.genres as Genre[];

                genres.forEach(genre => {
                    genreSelect.push({
                        genre: genre,
                        value: genre.name.toLowerCase(),
                        label: genre.name
                    })
                })
            }

            setGenreOptions(genreSelect)
        })
    }, []);

    const handleChangeGenre = (value: readonly GenreOptions[]) => {
        const genres: Genre[] = [];

        value.forEach((option) => {
            genres.push(option.genre)
        });

        setSelectedGenres(value)
        setBook({...book, genres: genres})
    }

    //Variable de lang
    const title = lang[langue].title;
    const description = lang[langue].description;

    return (
        <Container minH={"100vh"} maxW={"100%"} bg={"#EFE1D1"} p={"0"}>
            <BackHeader previousLink={"/lists/"} headerTitle={lang[langue].createBookTitle}/>

            <Center minH={"calc(100vh - 100px)"}>
                <Flex justifyContent={"space-between"}
                      alignItems={"center"}
                      flexDirection={{base: "column", lg: "row"}}
                      width={{base: "95%", sm: "95%", lg: "90%"}} gap={'15px'}>

                    {!book.image.id ?
                        <Box bg={"tb.500"} width={{base: "100%", lg: "40%"}} transition={"width 0.4s"} mt={"10px"}
                             borderRadius={"15px"} boxShadow={"lg"}>
                            <Dragger {...props} className={"bookUpload"}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined style={{color: "black"}}/>
                                </p>

                                <p className="ant-upload-text">{lang[langue].uploadTitle}</p>
                                <p className="ant-upload-hint">
                                    {lang[langue].uploadDesc}
                                    {lang[langue].extensions}
                                </p>
                            </Dragger>
                        </Box> :
                        <Center width={{base: "100%", lg: "40%"}}>
                            <Card bg={"tb.500"} transition={"width 0.4s"} mt={"10px"}
                                  width={{base: "100%", lg: "unset"}}>
                                <CardBody>
                                    <Center>
                                        <Image src={baseURL + "/" + book.image.path} alt={book.image.name} borderRadius='lg'
                                               mt={"15px"}
                                               loading="lazy"
                                               maxH={{
                                                   base: "350px",
                                                   md: "400px",
                                                   lg: "500px",
                                               }}
                                               transition={"max-height 0.7s"}
                                        />
                                    </Center>
                                </CardBody>
                                <Divider/>

                                <CardFooter>
                                    <Flex w={"100%"} justifyContent={'end'}>
                                        <Button
                                            bg={"tb.100"}
                                            _hover={{bg: "tb.200"}}
                                            onClick={() => {
                                                deleteGlobalImage(book.image).then(() => {
                                                    setBook({
                                                        ...book,
                                                        image: initEmptyImage()
                                                    })
                                                });
                                            }}>
                                            {lang[langue].edit}
                                        </Button>
                                    </Flex>
                                </CardFooter>
                            </Card>
                        </Center>
                    }

                    <Box bg={"tb.500"} transition={"width 0.4s"}
                         width={{base: "100%", lg: "50%", xl: "40%"}}
                         borderRadius={"15px"} p={"20px"}
                         boxShadow={"lg"} mb={"10px"}>
                        <Formik
                            initialValues={
                                {
                                    title: book.title,
                                    authorFirstName: book.author ? book.author.firstName : "",
                                    authorLastName: book.author ? book.author.lastName : "",
                                    editorName: book.editor ? book.editor.name : "",
                                    description: book.description,
                                    releaseDate: book.releaseDate
                                }
                            }

                            onSubmit={
                                (values, actions) => {
                                    setBook({
                                        ...book,
                                        title: values.title,
                                        author: {
                                            id: "",
                                            lastName: values.authorLastName,
                                            firstName: values.authorFirstName
                                        },
                                        editor: {
                                            id: "",
                                            name: values.editorName
                                        },
                                        releaseDate: dayjs(values.releaseDate).toDate(),
                                        description: values.description,
                                    });

                                    setTimeout(() => {
                                        actions.setSubmitting(false);
                                    }, 200);

                                    setSave(true);
                                }
                            }>
                            {(props) => (
                                <Form>
                                    <Flex direction={"column"} gap={"15px"}>
                                        <FormikTextInput<CreateBookInterface>
                                            type={InputTypeEnum.TEXT}
                                            fieldName="title"
                                            label={title}
                                            required={true}
                                            disabled={props.isSubmitting}
                                            errorFunction={(value) => getRequiredError(title, value)}
                                        />

                                        <Flex justifyContent={"space-between"} gap={"15px"}>
                                            <FormikTextInput<CreateBookInterface>
                                                type={InputTypeEnum.TEXT}
                                                fieldName="authorFirstName"
                                                label={lang[langue].firstNameAuthor}
                                                required={false}
                                                disabled={props.isSubmitting}
                                            />

                                            <FormikTextInput<CreateBookInterface>
                                                type={InputTypeEnum.TEXT}
                                                fieldName="authorLastName"
                                                label={lang[langue].lastNameAuthor}
                                                required={false}
                                                disabled={props.isSubmitting}
                                            />
                                        </Flex>

                                        <FormikTextInput<CreateBookInterface>
                                            type={InputTypeEnum.TEXT}
                                            fieldName="editorName"
                                            label={lang[langue].editor}
                                            required={false}
                                            disabled={props.isSubmitting}
                                        />

                                        <FormikTextInput<CreateBookInterface>
                                            type={InputTypeEnum.MONTH}
                                            fieldName="releaseDate"
                                            label={lang[langue].publicationDate}
                                            required={false}
                                            disabled={props.isSubmitting}
                                        />

                                        <FormControl>
                                            <FormLabel>{lang[langue].genre}</FormLabel>
                                            <Select
                                                isMulti
                                                variant={"custom"}
                                                name="genres"
                                                options={genreOptions}
                                                placeholder={lang[langue].selectGenre}
                                                closeMenuOnSelect={false}
                                                value={selectedGenres}
                                                onChange={(value) => handleChangeGenre(value)}
                                                colorScheme={"tb"}
                                                chakraStyles={selectStyle.chakraStyles}
                                            />
                                        </FormControl>

                                        <FormikTextArea<CreateBookInterface>
                                            fieldName="description"
                                            label={description}
                                            required={true}
                                            disabled={props.isSubmitting}
                                            errorFunction={(value) => getRequiredError(description, value)}
                                        />
                                    </Flex>

                                    <Flex justifyContent={"end"} mt={"15px"}>
                                        <Button
                                            bg={"tb.100"}
                                            _hover={{bg: "tb.200"}}
                                            color={"black"}
                                            type='submit'
                                            isLoading={props.isSubmitting}
                                        >
                                            {lang[langue].save}
                                        </Button>
                                    </Flex>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Flex>
            </Center>
        </Container>
    );
}

export default CreateBook;