import React, {FC, useEffect, useState} from "react";
import {fetchBookList, ListOptions} from "../../fetchActions/book/bookDetails";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {
    Button,
    CreateToastFnReturn,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import lang from "../../../lang.json";
import {langue} from "../../../function/function";
import {Select} from "chakra-react-select";
import {addBookToList, removeBookToList} from "../../../api/list";

interface AddBookListProps {
    idBook: string,
    toast: CreateToastFnReturn
    isOpen: boolean,
    onClose: () => void
    opened: boolean
}

const ModalAddBookList: FC<AddBookListProps> = ({idBook, isOpen, onClose, opened, toast}) => {
    const user = useSelector((state: RootState) => state.auth.user) as User;

    const [selectedList, setSelectedList] = useState<readonly ListOptions[]>([]);
    const [previousList, setPreviousList] = useState<readonly ListOptions[]>([]);
    const [listOptions, setListOptions] = useState<ListOptions[]>([])

    useEffect(() => {
        if (opened) {
            fetchBookList(user, idBook, setListOptions, setSelectedList, setPreviousList)
        }
    }, [opened]);

    const handleSaveList = (): void => {
        let error = false;
        const removeList: ListOptions[] = []
        const addList: ListOptions[] = []

        previousList.forEach((list) => {
            if (!selectedList.map(list => list.id).includes(list.id)) {
                removeList.push(list)
            }
        });

        selectedList.forEach((list) => {
            if (!previousList.map(list => list.id).includes(list.id)) {
                addList.push(list)
            }
        });

        removeList.forEach((list) => {
            removeBookToList(list.id, idBook).then((row) => {
                error = row.data.status === 200;
            })
        })

        addList.forEach((list) => {
            addBookToList(list.id, idBook).then((row) => {
                error = row.data.status === 200;
            })
        })

        if (!error) {
            toast({
                description: lang[langue].bookAdded,
                status: "success",
                duration: 2000,
                isClosable: true,
            })
            onClose();
        } else {
            toast({
                description: lang[langue].errorToast,
                status: "error",
                duration: 2000,
                isClosable: true,
            })
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>{lang[langue].addBookToList}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {listOptions.length > 0 ?
                            <FormControl>
                                <FormLabel> {lang[langue].chooseList}</FormLabel>
                                <Select
                                    isMulti
                                    name="colors"
                                    options={listOptions}
                                    placeholder={lang[langue].chooseList}
                                    closeMenuOnSelect={true}
                                    value={selectedList}
                                    onChange={setSelectedList}
                                    colorScheme={"tb"}
                                />
                            </FormControl> :
                            <Text>{lang[langue].noList}</Text>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='green' mr={3} onClick={handleSaveList}>
                            {lang[langue].save}
                        </Button>
                        <Button colorScheme={'red'} onClick={onClose}>
                            {lang[langue].cancel}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ModalAddBookList