import {inputAnatomy} from '@chakra-ui/anatomy'
import {createMultiStyleConfigHelpers} from '@chakra-ui/react'

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(inputAnatomy.keys)

const variantBase = definePartsStyle(() => {
    return {
        field: {
            border: "solid 1.5px",
            borderColor: "tb.700",
            bg: "transparent",

            _hover: {
                borderColor: "tb.900",
            },
        },

        addon: {
            bg: "tb.700",
            color: "white"
        }
    }
})

const tbTheme = definePartsStyle(() => {
    return {
        field: {
            border: "solid 1px",
            borderColor: "tb.500",
            backgroundColor: "transparent",
            color: "white",

            _hover: {
                borderColor: "tb.600",
            },
        },

        addon: {
            bg: "tb.500",
            color: "white",
            _hover: {
                bg: "tb.600",
            },
        }
    }
})

// themes/stepper.ts

const baseStyle = {
    // select the indicator part
    indicator: {
        // change the default border radius to 0
        borderRadius: 0,
    },
}

export const stepperTheme = {
    baseStyle,
}

const variants = {
    tb: tbTheme,
    custom: variantBase,
}

export const inputTheme = defineMultiStyleConfig({variants})


export const selectStyle = {
    chakraStyles: {
        placeholder: (provided: any) => ({
            ...provided,
            color: "rgba(63,63,63,0.8)",
        })
    },
};